<template>
	<div>
		<a-tabs default-active-key="2">
			<a-tab-pane key="1" tab="Szerződés lezárása" v-if="$store.state.auth.userType != 'AUTOHASZNALO'">
				<div class="tab-content-wrapper">
					<a-row type="flex" justify="center">
						<a-col :lg="{ span: 8 }" :md="{ span: 12 }" :xs="{ span: 20 }">
							<a-form :form="form1" @submit="submit1">
								<a-form-item label="Kérjük, válasszon rendszámot">
									<a-select
										v-decorator="[
											'vehicle',
											{ rules: [{ required: true, message: 'Kérjük, válasszon ki egy járművet!' }] },
										]"
									>
										<a-select-option v-for="vehicle in vehicles" :key="vehicle.szerzodesid">
											{{ vehicle.rendszam }}
										</a-select-option>
									</a-select>
								</a-form-item>
								<a-form-item label="Kérjük, jelölje meg a jármű visszavétel tervezett idejét">
									<a-date-picker
										v-decorator="[
											'date',
											{
												rules: [
													{ required: true, message: 'Kérjük, adja meg a visszavétel tervezett idejét!' },
												],
											},
										]"
										placeholder="Válasszon dátumot!"
										style="width:100%"
									/>
								</a-form-item>
								<p class="ant-form-item-required">Van olyan ismerőse, aki megvásárolná a használt gépjárművet:</p>
								<a-form-item style="text-align:center;">
									<a-radio-group v-model="newCustomer">
										<a-radio :value="1">Igen</a-radio>
										<a-radio :value="0">Nem</a-radio>
									</a-radio-group>
								</a-form-item>
								<p class="ant-form-item-required">
									Szeretne-e új autóra ajánlatot kérni tartósbérleti konstrukcióban:
								</p>
								<a-form-item style="text-align:center;">
									<a-radio-group v-model="newOffer">
										<a-radio :value="1">Igen</a-radio>
										<a-radio :value="0">Nem</a-radio>
									</a-radio-group>
								</a-form-item>
								<a-form-item style="text-align:center;">
									<a-button type="primary" html-type="submit">Küldés</a-button>
								</a-form-item>
							</a-form>
						</a-col>
					</a-row>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="Ajánlatkérés" force-render>
				<div class="tab-content-wrapper">
					<a-row type="flex" justify="center">
						<a-col :lg="{ span: 8 }" :md="{ span: 12 }" :xs="{ span: 20 }">
							<a-form :form="form2" @submit="submit2">
								<p>
									Lecserélné meglévő autóját vagy bővítené flottáját? <br />
									Kérjük, adja meg az ajánlatkészítéshez szükséges legfontosabb adatokat és értékesítő kollégánk 24
									órán belül megkeresi Önt személyre szabott ajánlattal.
								</p>
								<a-form-item>
									<a-input
										v-decorator="[
											'vehicle',
											{
												rules: [
													{ required: true, message: 'Kérjük, adja meg, hogy milyen jármű típus érdekli!' },
												],
											},
										]"
										placeholder="Milyen márkák, modellek érdeklik ? ( pl. Skoda Octavia 1.4)"
									/>
								</a-form-item>
								<a-form-item>
									<a-select
										v-decorator="[
											'milage',
											{
												initialValue: milages[0].label,
												rules: [{ len: 5, message: 'Kérjük, adja meg a tervezett éves futásteljesítményt!' }],
											},
										]"
									>
										<a-select-option v-for="milage in milages" :key="milage.value">{{
											milage.label
										}}</a-select-option>
									</a-select>
								</a-form-item>
								<p>
									Kollégáink több tízezer gépjármű értékcsökkenési görbéjének és üzemeltetési költségeinek
									statisztikai adatbázisából képesek meghatározni az ideáis futamidőt az Ön által megadott éves
									futásteljesítmény alapján.
								</p>
								<a-form-item>
									<a-select v-model="duration" :default-value="durations[0].value">
										<a-select-option v-for="duration in durations" :key="duration.value">{{
											duration.label
										}}</a-select-option>
									</a-select>
								</a-form-item>
								<a-form-item style="text-align:center;">
									<a-button type="primary" html-type="submit">Küldés</a-button>
								</a-form-item>
							</a-form>
						</a-col>
					</a-row>
				</div>
			</a-tab-pane>
			<a-tab-pane key="3" tab="Szolgáltatások megrendelése">
				<div class="tab-content-wrapper">
					<a-row type="flex" justify="center">
						<a-col :xl="{ span: 6 }" :lg="{ span: 8 }" :md="{ span: 8 }" :sm="{ span: 16 }" :xs="{ span: 20 }">
							<a-select
								class="plateSelector"
								@change="handleChange"
								style="width: 100%"
								placeholder="Kérjük válasszon rendszámot !"
							>
								<a-select-option v-for="vehicle in vehicles" :key="vehicle.szerzodesid">
									{{ vehicle.rendszam }}
								</a-select-option>
							</a-select>

							<a-divider />
						</a-col>
					</a-row>
					<div class="desktop" v-if="$mq === 'md' || $mq === 'lg'">
						<a-row type="flex" justify="center" v-show="show">
							<a-col :span="20">
								<h1 class="servicesTitle">Aktív szolgáltatások</h1>
								<hr />
								<a-row class="services">
									<div v-for="service in serviceList()" :key="service.id">
										<a-col
											v-if="service.statusz === 'AKTIV'"
											:xl="{ span: 8 }"
											:lg="{ span: 12 }"
											:md="{ span: 12 }"
											:xs="{ span: 24 }"
										>
											<a-card style="min-height: 8em">
												<a-row type="flex" justify="center" align="middle">
													<a-col :span="3">
														<img v-if="service.tipusnev === 'Tartósbérlet'" src="@/assets/icons/Wigo_icons_redesign_key.svg" />
														<img
															v-if="
																service.tipusnev === 'Cégautó adó' ||
																	service.tipusnev === 'Gépjárműadó'
															"
															src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
														/>
														<img
															v-if="service.tipusnev === 'KGFB' || service.tipusnev === 'CASCO'"
															src="@/assets/icons/Wigo_icons_redesign_shield.svg"
														/>
														<img
															v-if="
																service.tipusnev === 'GAP'
															"
															src="@/assets/icons/Wigo_icons_redesign_shield_check.svg"
														/>
														<img
															v-if="
																	service.tipusnev === 'Kiterjesztett CASCO'
															"
															src="@/assets/icons/Wigo_icons_redesign_shield_check_plus.svg"
														/>
														<img
															v-if="service.tipusnev === 'Autópálya használat'"
															src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
														/>
														<img
															v-if="
																service.tipusnev === 'Belföldi segélyszolgálat' ||
																	service.tipusnev === 'Külföldi segélyszolgálat'
															"
															src="@/assets/icons/Wigo_icons_redesign_customer_support.svg"
														/>
														<img
															v-if="
																service.tipusnev === 'Cseregépjármű szolgáltatás'
															"
															src="@/assets/icons/Wigo_icons_redesign_car_side.svg"
														/>
														<img
															v-if="
																	service.tipusnev === 'Hozom-viszem szolgáltatás'
															"
															src="@/assets/icons/Wigo_icons_redesign_distance.svg"
														/>
														<img
															v-if="service.tipusnev === 'Flotta management'"
															src="@/assets/icons/Wigo_icons_redesign_payment.svg"
														/>
														<img v-if="service.tipusnev === 'GPS'" src="@/assets/icons/Wigo_icons_redesign_poi_map.svg" />
														<img
															v-if="service.tipusnev === 'Gumiabroncs szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_wheel.svg"
														/>
														<img
															v-if="service.tipusnev === 'Parkolás szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_parkin_spot.svg"
														/>
														<img
															v-if="service.tipusnev === 'Szerviz szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_engine.svg"
														/>
														<img v-if="service.tipusnev === 'Üzemanyagkártya'" src="@/assets/icons/Wigo_icons_redesign_fuel_card.svg" />
													</a-col>
													<a-col :span="15">
														<p v-if="service.tipusnev === 'Flotta management'">Flotta díj</p>
														<p v-else-if="service.tipusnev === 'Hozom-viszem szolgáltatás'">
															Hozom-viszem szolg.
														</p>
														<p v-else-if="service.tipusnev === 'GAP'">
															GAP biztosítás
														</p>
														<p v-else-if="service.tipusnev === 'GPS'">
															GPS szolgáltatás
														</p>

														<p v-else>{{ service.tipusnev }}</p>
													</a-col>

													<a-col :span="3"><img src="@/assets/active.png"/></a-col>
												</a-row>
											</a-card>
										</a-col>
									</div>
								</a-row>
								<h1 class="servicesTitle">Megrendelés alatt</h1>
								<hr />
								<a-row>
									<div v-for="service in serviceList()" :key="service.id" class="services">
										<a-col
											v-if="
												service.statusz === 'MEGRENDELES_ALATT' &&
													service.tipusnev != 'Flotta management' &&
													service.tipusnev != 'Cégautó adó' &&
													service.tipusnev != 'Gépjárműadó'
											"
											:xl="{ span: 8 }"
											:lg="{ span: 12 }"
											:md="{ span: 12 }"
											:xs="{ span: 24 }"
										>
											<a-card style="min-height: 8em">
												<a-row type="flex" justify="center" align="middle">
													<a-col
														:xl="{ span: 3 }"
														:lg="{ span: 3 }"
														:md="{ span: 3 }"
														:sm="{ span: 3 }"
														:xs="{ span: 3 }"
													>
														<img v-if="service.tipusnev === 'Tartósbérlet'" src="@/assets/icons/Wigo_icons_redesign_key.svg" />

														<img
															v-if="service.tipusnev === 'KGFB' || service.tipusnev === 'CASCO'"
															src="@/assets/icons/Wigo_icons_redesign_shield.svg"
														/>
														<img
															v-if="
																service.tipusnev === 'GAP'
															"
															src="@/assets/icons/Wigo_icons_redesign_shield_check.svg"
														/>
														<img
															v-if="
																	service.tipusnev === 'Kiterjesztett CASCO'
															"
															src="@/assets/icons/Wigo_icons_redesign_shield_check_plus.svg"
														/>
														<img
															v-if="service.tipusnev === 'Autópálya használat'"
															src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
														/>
														<img v-if="service.tipusnev === 'GPS'" src="@/assets/icons/Wigo_icons_redesign_poi_map.svg" />
														<img
															v-if="
																service.tipusnev === 'Belföldi segélyszolgálat' ||
																	service.tipusnev === 'Külföldi segélyszolgálat'
															"
															src="@/assets/icons/Wigo_icons_redesign_customer_support.svg"
														/>
														<img
															v-if="
																service.tipusnev === 'Cseregépjármű szolgáltatás'
															"
															src="@/assets/icons/Wigo_icons_redesign_car_side.svg"
														/>
														<img
															v-if="
																	service.tipusnev === 'Hozom-viszem szolgáltatás'
															"
															src="@/assets/icons/Wigo_icons_redesign_distance.svg"
														/>

														<img
															v-if="service.tipusnev === 'Gumiabroncs szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_wheel.svg"
														/>
														<img
															v-if="service.tipusnev === 'Parkolás szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_parkin_spot.svg"
														/>
														<img
															v-if="service.tipusnev === 'Szerviz szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_engine.svg"
														/>
														<img v-if="service.tipusnev === 'Üzemanyagkártya'" src="@/assets/icons/Wigo_icons_redesign_fuel_card.svg" />

														<img
															v-if="service.tipusnev === 'Flotta management'"
															src="@/assets/icons/Wigo_icons_redesign_payment.svg"
														/>
														<img
															v-if="
																service.tipusnev === 'Cégautó adó' ||
																	service.tipusnev === 'Gépjárműadó'
															"
															src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
														/>
													</a-col>
													<a-col :span="20">
														<p v-if="service.tipusnev === 'Flotta management'">Flotta díj</p>
														<p v-else-if="service.tipusnev === 'Hozom-viszem szolgáltatás'">
															Hozom-viszem szolg.
														</p>
														<p v-else-if="service.tipusnev === 'GAP'">
															GAP biztosítás
														</p>
														<p v-else-if="service.tipusnev === 'GPS'">
															GPS szolgáltatás
														</p>
														<p v-else>{{ service.tipusnev }}</p>
													</a-col>
												</a-row>
											</a-card>
										</a-col>
									</div>
								</a-row>

								<h1 class="servicesTitle">Inaktív szolgáltatások</h1>
								<hr />
								<a-row>
									<div v-for="service in serviceList()" :key="service.id" class="services">
										<a-col
											v-if="
												service.statusz === 'INAKTIV' &&
													service.tipusnev != 'Flotta management' &&
													service.tipusnev != 'Cégautó adó' &&
													service.tipusnev != 'Gépjárműadó'
											"
											:xl="{ span: 8 }"
											:lg="{ span: 12 }"
											:md="{ span: 12 }"
											:xs="{ span: 24 }"
										>
											<a-card style="min-height: 8em">
												<a-row type="flex" justify="space-between" align="middle">
													<a-col :span="3" class="imgContainer">
														<img v-if="service.tipusnev === 'Tartósbérlet'" src="@/assets/icons/Wigo_icons_redesign_key.svg" />
														<img
															v-if="
																service.tipusnev === 'Cégautó adó' ||
																	service.tipusnev === 'Gépjárműadó'
															"
															src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
														/>
														<img
															v-if="service.tipusnev === 'KGFB' || service.tipusnev === 'CASCO'"
															src="@/assets/icons/Wigo_icons_redesign_shield.svg"
														/>
														<img
															v-if="
																service.tipusnev === 'GAP'
															"
															src="@/assets/icons/Wigo_icons_redesign_shield_check.svg"
														/>
														<img
															v-if="
																	service.tipusnev === 'Kiterjesztett CASCO'
															"
															src="@/assets/icons/Wigo_icons_redesign_shield_check_plus.svg"
														/>
														<img
															v-if="service.tipusnev === 'Autópálya használat'"
															src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
														/>
														<img v-if="service.tipusnev === 'GPS'" src="@/assets/icons/Wigo_icons_redesign_poi_map.svg" />
														<img
															v-if="
																service.tipusnev === 'Belföldi segélyszolgálat' ||
																	service.tipusnev === 'Külföldi segélyszolgálat'
															"
															src="@/assets/icons/Wigo_icons_redesign_customer_support.svg"
														/>
														<img
															v-if="
																service.tipusnev === 'Cseregépjármű szolgáltatás'
															"
															src="@/assets/icons/Wigo_icons_redesign_car_side.svg"
														/>
														<img
															v-if="
																	service.tipusnev === 'Hozom-viszem szolgáltatás'
															"
															src="@/assets/icons/Wigo_icons_redesign_distance.svg"
														/>
														<img
															v-if="service.tipusnev === 'Flotta management'"
															src="@/assets/icons/Wigo_icons_redesign_payment.svg"
														/>
														<img
															v-if="service.tipusnev === 'Gumiabroncs szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_wheel.svg"
														/>
														<img
															v-if="service.tipusnev === 'Parkolás szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_parkin_spot.svg"
														/>
														<img
															v-if="service.tipusnev === 'Szerviz szolgáltatás'"
															src="@/assets/icons/Wigo_icons_redesign_engine.svg"
														/>
														<img v-if="service.tipusnev === 'Üzemanyagkártya'" src="@/assets/icons/Wigo_icons_redesign_fuel_card.svg" />
													</a-col>
													<a-col :span="10">
														<p v-if="service.tipusnev === 'Flotta management'">Flotta díj</p>
														<p v-else-if="service.tipusnev === 'Hozom-viszem szolgáltatás'">
															Hozom-viszem szolg.
														</p>
														<p v-else-if="service.tipusnev === 'GAP'">
															GAP biztosítás
														</p>
														<p v-else-if="service.tipusnev === 'GPS'">
															GPS szolgáltatás
														</p>
														<p v-else>{{ service.tipusnev }}</p>
													</a-col>

													<a-col :span="11">
														<a-button shape="round" @click="orderService(service.tipus)"
															>Ajánlatot kérek</a-button
														>
													</a-col>
												</a-row>
											</a-card>
										</a-col>
									</div>
								</a-row>
							</a-col>
						</a-row>
					</div>

					<div class="mobile" v-if="$mq === 'sm' || $mq === 'tablet'">
						<h1 class="servicesTitle">Aktív szolgáltatások</h1>
						<hr />
						<div v-for="service in serviceList()" :key="service.id">
							<a-row v-if="service.statusz === 'AKTIV'">
								<a-col :span="24">
									<a-card :title="service.tipusnev">
										<p>{{ service.dij | filterNumbers }} Ft</p></a-card
									>
								</a-col>
							</a-row>
						</div>
						<h1 class="servicesTitle">Megrendelés alatt</h1>
						<hr />
						<div v-for="service in serviceList()" :key="service.id">
							<a-row v-if="service.statusz === 'MEGRENDELES_ALATT'">
								<a-col :span="24">
									<a-card>
										<p>{{ service.tipusnev }}</p>
									</a-card>
								</a-col>
							</a-row>
						</div>
						<h1 class="servicesTitle">Inaktív szolgáltatások</h1>
						<hr />
						<div v-for="service in serviceList()" :key="service.id">
							<a-row v-if="service.statusz === 'INAKTIV'">
								<a-col :span="24">
									<a-card :title="service.tipusnev">
										<a-button shape="round" @click="orderService(service.tipus)">Ajánlatot kérek</a-button>
									</a-card>
								</a-col>
							</a-row>
						</div>
					</div>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import { mapState } from 'vuex';

const milages = [
	{ value: '0', label: 'Éves futásteljesítmény' },
	{ value: '10000', label: '10.000 km' },
	{ value: '20000', label: '20.000 km' },
	{ value: '30000', label: '30.000 km' },
	{ value: '40000', label: '40.000 km' },
	{ value: '50000', label: '50.000 km' },
	{ value: '60000', label: '60.000 km' },
];
const durations = [
	{ value: '0', label: 'Optimális futamidőre kérek ajánlatot' },
	{ value: '12', label: '12 hónapra kérek ajánlatot' },
	{ value: '24', label: '24 hónapra kérek ajánlatot' },
	{ value: '36', label: '36 hónapra kérek ajánlatot' },
	{ value: '48', label: '48 hónapra kérek ajánlatot' },
];
const duration = '0';

export default {
	data() {
		return {
			form1: this.$form.createForm(this, { name: 'form1' }),
			form2: this.$form.createForm(this, { name: 'form2' }),
			form3: this.$form.createForm(this, { name: 'form3' }),
			contractId: '',
			milages,
			durations,
			duration,
			newCustomer: 1,
			newOffer: 1,
			show: false,
		};
	},

	created() {
		this.$store.dispatch('listVehiclesAdministration', {
			page: 0,
		});
	},

	computed: {
		...mapState({ vehicles: (state) => state.auth.administrationVehicles, link: (state) => state.auth.link }),
		// Other properties
	},

	methods: {
		submit1(e) {
			e.preventDefault();
			this.form1.validateFields();
			var errors = this.form1.getFieldsError();
			if (errors.vehicle !== undefined || errors.milage !== undefined) {
				return false;
			}
			this.$store
				.dispatch('requestClosingOffer', {
					id: this.form1.getFieldValue('vehicle'),
					closing: {
						date: this.form1.getFieldValue('date').format('YYYY-MM-DD'),
						customer: this.newCustomer ? true : false,
						newOffer: this.newOffer ? true : false,
					},
				})
				.then(() => {
					this.form1.resetFields();
					this.newCustomer = this.newOffer = 1;
					this.$showNotification(
						'Rendben',
						'A lezárási ajánlat elküldése sikeresen megtörtént!',
						6,
						<a-icon type="check-circle" style="color:#00ce55" />
					);
				})
				.catch((err) => {
					this.$showNotification('Hiba', err.response.data.detail, 6, <a-icon type="close-circle" style="color:#fc0a33" />);
				});
		},

		submit2(e) {
			e.preventDefault();
			this.form2.validateFields();
			var errors = this.form2.getFieldsError(),
				nameItems = [];
			if (errors.vehicle !== undefined || errors.milage !== undefined) {
				return false;
			}
			nameItems = this.form2.getFieldValue('vehicle').split(' ');
			this.$store
				.dispatch('requestNewVehicleOffer', {
					brand: nameItems[0].trim(),
					model: nameItems[1] ? nameItems[1].trim() : '',
					engine: nameItems[2] ? nameItems[2].trim() : '',
					equipment: nameItems[3] ? nameItems[3].trim() : '',
					paymentPeriod: this.duration,
					yearlyMileage: this.form2.getFieldValue('milage'),
				})
				.then(() => {
					this.form2.resetFields();
					this.$showNotification(
						'Rendben',
						'Az új járműre vonatkozó ajánlat kérés elküldése sikeresen megtörtént!',
						6,
						<a-icon type="check-circle" style="color:#00ce55" />
					);
				})
				.catch((err) => {
					this.$showNotification('Hiba', err.response.data.detail, 6, <a-icon type="close-circle" style="color:#fc0a33" />);
				});
		},

		handleChange(value) {
			this.show = true;
			this.contractId = value;
			this.$store
				.dispatch('listServices', {
					id: value,
				})
				.then(() => {
					this.serviceList();
				})
				.catch((err) => {
					this.$showNotification('Hiba', err.response.data.detail, 6, <a-icon type="close-circle" style="color:#fc0a33" />);
				});
		},

		orderService(type) {
			this.$store
				.dispatch('requestServices', {
					vehicleId: this.contractId,
					orderedServiceType: type,
				})
				.then(() => {
					this.$showNotification(
						'Rendben',
						'Sikeres megrendelés!',
						6,
						<a-icon type="check-circle" style="color:#00ce55" />
					);
				})
				.catch((err) => {
					this.$showNotification('Hiba', err.response.data.detail, 6, <a-icon type="close-circle" style="color:#fc0a33" />);
				});
		},

		serviceList() {
			return this.$store.state.auth.serviceList;
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/_global.scss';
</style>

<style lang="scss" scoped>
.ant-btn-primary.ant-btn {
	background: #6d8cb5!important;
	border-color: #6d8cb5 !important;
}
.inactiveResponsive {
	display: none;
}
hr {
	border: 1px solid #1f4e8d;
}

/deep/ .plateSelector .ant-select-selection {
	height: 4em;
}

/deep/ .plateSelector .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
	height: 4em;
	font-size: 1.5em;
	padding: 0.5em;
}

/deep/ .plateSelector .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
	height: 4em;
	font-size: 1.5em;
	padding: 0.5em;
}

/deep/ .ant-form-item-required::before {
	display: none !important;
}

.questionnaire {
	padding: 1em;
}

.services .ant-divider {
	background: #1f4e8d;
	font-size: 1.5em;
}

.services img {
	width: 75%;
}

.services p {
	font-size: 0.9em;
	margin-top: 0px !important;
	margin-bottom: 0em !important;
}

.servicesTitle {
	color: #1f4e8d;
	font-size: 2em;
	font-weight: 900;
}

/deep/ .ant-card {
	text-align: center !important;
	color: #1f4e8d;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
	border: none;
	margin: 15px;
	border-radius: 10px 10px !important;
	min-height: 55px;
}
/deep/ .ant-card-body {
	text-align: center !important;
	color: #1f4e8d;
	font-size: 1.5em;
}
/deep/ .ant-card-head {
	padding: 0px 0px !important;
	border-radius: 10px 10px !important;
}
/deep/ .ant-card-head-title {
	text-align: center !important;
	color: white;
	background: #1f4e8d !important;
	font-size: 1.5em;
	border-radius: 10px 10px 0px 0px !important;
}

/deep/ .ant-card-head {
	background: #1f4e8d !important;
}

@media only screen and (max-width: 1700px) {
	/deep/ .ant-card-body {
		font-size: 1em;
		padding-top: 3em;
	}
}

@media only screen and (max-width: 1300px) {
	/deep/ .ant-btn > span {
		font-size: 0.8em;
	}
}
@media only screen and (max-width: 1200px) and (min-width: 770px) {
	/deep/ .ant-btn-round {
		margin-left: 1.25em;
		padding: 0 0.5em;
	}
}

@media only screen and (max-width: 900px) {
	.imgContainer {
		display: none;
	}
}
@media only screen and (max-width: 576px) {
	/* Target devices narrower than 578px. */

	/deep/ .plateSelector .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
		height: 4em;
		font-size: 0.75em;
		padding: 1em 0em;
	}

	/deep/ .plateSelector .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
		height: 4em;
		font-size: 2em;
		padding: 0.5em 0em;
	}
	/deep/ .ant-tabs-tab-active,
	/deep/ .ant-tabs-tab {
		font-size: 0.7em !important;
	}

	/deep/ .ant-tabs-bar {
		padding: 0 0 !important;
	}

	/deep/ .ant-card-head-title {
		font-size: 1em;
	}

	.servicesTitle {
		font-size: 1em;
	}
}

@media (min-width: 1871px) {
	/deep/ .ant-card-body {
		padding-top: 1.5em;
	}
}
</style>
